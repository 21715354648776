<template>
    <content-view>
        <regulation-page/>
    </content-view>
</template>

<script>
import ContentView from '@/views/menu/ContentView'
import RegulationPage from '@/components/regulation/RegulationPage'
import { metaTitle } from '@/mixins/meta_title'

export default {
    name: 'Regulator',
    mixins: [metaTitle],
    components: { RegulationPage, ContentView }
}
</script>

<style scoped>

</style>
