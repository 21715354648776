<template>
    <li class="regulation__card">
        <div class="card__group">
            <img :src="item.image || defaultAvatar" class="card__avatar"/>
            <span class="card__name card__name--desktop">{{item.fio}}</span>
        </div>
        <div class="card__wrap">
            <span class="card__name card__name--tablet">{{item.fio}}</span>
            <div class="card__group">
                <div v-if="item.experience" class="card__group-item">
                    <span class="card__title">Опыт работы</span>
                    <span class="card__text">{{item.experience_in_years}}</span>
                </div>
                <div v-if="item.methodologies" class="card__group-item">
                    <span class="card__title">Методологии</span>
                    <span class="card__text">{{item.methodologies}}</span>
                </div>
                <div v-if="item.price" class="card__group-item">
                    <span class="card__title">Стоимость часа</span>
                    <span class="card__text">{{item.price}} ₽/час</span>
                </div>
                <button @click="openModal" class="v-btn card__btn">Подробнее</button>
            </div>
        </div>
    </li>
</template>

<script>
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "RegulationCard",
        data() {
            return {
                defaultAvatar,
            }
        },
        props: {
            item: {
                type: Object
            }
        },
        methods: {
            openModal() {
                const RegulationStaff = () => import(`./modal/RegulationStaff`);
                this.$modal.show(RegulationStaff, {
                    data: this.item
                }, {
                    name: 'RegulationStaff',
                    adaptive: true,
                    maxWidth: 900,
                    width: '100%',
                    height: 'auto'
                })
            }
        }
    }
</script>

<style lang="sass" scoped>
    @import "#sass/v-style"

    .regulation__card
        padding: 20px
        background: #FFF
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07)
        display: flex
        align-items: center
        justify-content: space-between
        &:hover
            outline: 4px solid $gold
            .card__btn
                background-color: $gold
        &:nth-child(n+2)
            margin-top: 10px
        .card
            &__group
                display: flex
                align-items: center
                &:first-child
                    flex-basis: 25%
                &-item
                    display: flex
                    flex-direction: column
                    flex-basis: 20%
                    &:nth-child(n+2)
                        margin-left: 75px
            &__title
                color: #A4AEBD
                font-weight: 300
                font-size: 0.875rem
            &__text
                color: #000
                font-weight: 600
            &__avatar
                height: 100px
                width: 100px
                border-radius: 50%
                border: 5px solid transparent
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07)
            &__wrap
                flex-basis: 70%
            &__name
                font-weight: 600
                color: #000
                margin-left: 25px
                &--tablet
                    display: none
            &__btn
                background-color: $btn-disabled
                margin-left: auto
    @media (max-width: 1000px)
        .card
            &__group
                &:first-child
                    flex-basis: auto
                &-item
                    &:nth-child(n+2)
                        margin-left: 30px
            &__wrap
                flex-basis: 80%
                margin: 0 40px
            &__name
                display: none
                &--tablet
                    display: flex
                    margin-left: 0
    @media (max-width: 600px)
        .regulation__card
            flex-direction: column
            align-items: flex-start
        .card
            &__group
                &:last-child
                    flex-direction: column
                    align-items: flex-start
                &-item
                    margin-left: 0 !important
                    margin-top: 15px
            &__name
                margin-top: 10px
                &--desktop
                    display: inline
                &--tablet
                    display: none
            &__wrap
                margin: 0
                width: 100%
            &__btn
                margin-top: 15px
                margin-right: auto


</style>
